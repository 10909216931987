import axios from 'axios'
// import auth from '@/utils/app-shell-auth'
import i18n from '@/plugins/i18n/i18n'
import getService from '@/utils/get-service'
import getTenant from '@/utils/get-tenant'

const defaultHeaders = {
  'Content-Type': 'application/json',
  'x-tenant-id': getTenant()
}

const timeout = 5000

const ApiOrd = axios.create({
  baseURL: getService().VUE_APP_API_ORD,
  timeout
})

ApiOrd.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      ...defaultHeaders
    }

    config.params = {
      ...config.params,
      lang: i18n.t('language')
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

const ApiAct = axios.create({
  baseURL: `${window.parent.IFC_VARIABLES.baseURL}/account/main/admin/`,
  timeout
})

ApiAct.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      ...defaultHeaders
    }

    config.params = {
      ...config.params,
      lang: i18n.t('language')
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

const ApiPyt = axios.create({
  baseURL: getService().VUE_APP_API_PAY,
  timeout
})

ApiPyt.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      ...defaultHeaders
    }

    config.params = {
      ...config.params,
      lang: i18n.t('language')
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

const ApiSalesOperator = axios.create({
  baseURL: getService().VUE_APP_API_SO,
  timeout
})

ApiSalesOperator.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      ...defaultHeaders
    }
    config.params = {
      ...config.params,
      lang: i18n.t('language')
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export { ApiAct, ApiOrd, ApiPyt, ApiSalesOperator, axios }
