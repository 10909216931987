import Vue from 'vue'
import VueRouter from 'vue-router'
// import keycloak from '@/plugins/keycloak/keycloak'
import routes from './routers'

Vue.use(VueRouter)

export const createRouter = (base = '') => {
  const router = new VueRouter({
    mode: 'hash',
    base,
    routes: [
      ...routes,
      {
        path: '*',
        redirect: {
          path: '/'
        }
      }
    ]
  })

  return router
}

// const setPageTitle = to => {
//  document.title = to.meta.title
// }

// const checkAuth = async (to) => {
//  const auth = await keycloak
//  return !to.meta.auth || auth.authenticated
// }

// router.beforeEach((to, from, next) => {
//  if (checkAuth(to)) {
//    setPageTitle(to)
//    next()
//  } else {
//    keycloak.logout()
//  }
// })

export default createRouter()
