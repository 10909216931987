<template>
  <div>
    <v-app>
      <div class="order">
        <router-view />
      </div>
    </v-app>
  </div>
</template>
<script>
import router from './router'

export default {
  name: 'App',
  watch: {
    $route: function ({ path, fullPath, query }) {
      window.parent.postMessage({ path, fullPath, query }, '*')
    }
  },

  mounted() {
    // eslint-disable-next-line space-before-function-paren
    function handleRouteChange(event) {
      router.push(event.detail.newRoute.replace('/', ''))
    }

    window.addEventListener('changeRoute', handleRouteChange)

    // Make sure to remove the event listener when the component is destroyed
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('changeRoute', handleRouteChange)
    })
  }
}
</script>

<style lang="scss">
.order {
  background-color: #fafafc;
}
</style>
