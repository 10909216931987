const getUrl = (path) => {
  return window.parent.IFC_VARIABLES.baseURL + path
}

const getService = () => {
  return {
    VUE_APP_API_ORD: getUrl(process.env.VUE_APP_API_ORD_PATH),
    VUE_APP_API_ACT: getUrl(process.env.VUE_APP_API_ACT_PATH),
    VUE_APP_API_PAY: getUrl(process.env.VUE_APP_API_PAY_PATH),
    VUE_APP_API_SO: getUrl(process.env.VUE_APP_API_SO_PATH)
  }
}

export default getService
