import Vue from 'vue'
import Vuex from 'vuex'
import Order from './Order'
import SalesChannel from './SalesChannel'
import PaymentOptions from './PaymentOptions'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    keycloak: null
  },
  mutations: {
    SET_KEYCLOAK(state, keycloak) {
      state.keycloak = keycloak
    }
  },
  modules: {
    Order,
    SalesChannel,
    PaymentOptions
  }
})
