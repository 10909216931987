export default [
  {
    path: '/',
    name: 'ordersList',
    component: () => import('@/views/Order/List.vue'),
    meta: {
      title: 'Orders List',
      auth: true
    }
  },
  {
    path: '/relocation',
    name: 'OrdersRelocation',
    props: true,
    component: () => import('@/views/Order/Relocation.vue'),
    meta: {
      title: 'Relocation',
      auth: true
    }
  },
  {
    path: '/:id',
    name: 'OrderDetails',
    props: true,
    component: () => import('@/views/Order/Details.vue'),
    meta: {
      title: 'Order Details',
      auth: true
    }
  },
  {
    path: '/:id/delivery/:deliveryId',
    name: 'DeliveryDetails',
    props: true,
    component: () => import('@/views/Order/Delivery.vue'),
    meta: {
      title: 'Delivery Details',
      auth: true
    }
  }
]
