import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import App from '@/App.vue'
import router, { createRouter } from '@/router'
import store from '@/store'
import plugins from '@/plugins'
import auth from '@/utils/app-shell-auth'
import { version } from '../package.json'

Vue.mixin({
  computed: {
    $auth: () => auth,
    $mfeUrl: () =>
      `${window.parent.ENV.CDN_DOMAIN}/@infralabs/ifc-order-app-admin/${version}`
  }
})

var postMessageTemp = window.parent.postMessage
window.parent.postMessage = function (message, targetOrigin, transfer) {
  postMessageTemp(JSON.parse(JSON.stringify(message)), targetOrigin, transfer)
}

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    router,
    store,
    ...plugins,
    render: (h) => h(App),
    el: '#mfe_div_connector'
  },
  handleInstance(app, props) {
    auth.listen(app.tokenIdSubject, app.$root.$emit, app.$root)
  }
})

export const bootstrap = (props) => {
  return Promise.resolve().then(() => {
    vueLifecycles.bootstrap(props)
  })
}

export const mount = (props) => {
  return Promise.resolve().then(() => {
    vueLifecycles.mount(props)
  })
}

export const unmount = (props) => {
  return Promise.resolve().then(() => {
    vueLifecycles.unmount(props)
  })
}

export const withoutSingleSpa = ({ elementDOM, basePath, location, auth }) => {
  const options = {
    store,
    ...plugins
  }

  console.log('withoutSingleSpa called')

  return new Vue({
    mixins: [
      {
        router: createRouter(basePath),
        mounted: function () {
          if (location) {
            router.push(location)
          }
        },
        ...options,
        computed: {
          $auth: () => auth
        }
      }
    ],
    render(h) {
      return h(App, {})
    }
  }).$mount(elementDOM)
}
