import { ApiAct } from '@/plugins/axios/axios'

export default class AccountService {
  static salesChannels() {
    const url = `accounts/${window.parent.IFC_VARIABLES.headers['x-tenant-id']}/saleschannels?perPage=100`
    return ApiAct.get(url).then((res) => res.data.data.saleschannels)
  }
}

export const accountLocations = async () => {
  const { data } = await ApiAct('/accounts', {
    params: {
      accountType: 'location',
      perPage: Number.MAX_SAFE_INTEGER
    }
  })

  return data
}
